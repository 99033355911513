import { createSlice } from '@reduxjs/toolkit'
export const staticmethodSlice = createSlice({
  name: 'staticmethod',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    staticincrement: (state) => {
      state.value = "stmp.png";
      state.z=true
      state.im="stmpic.png"
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { staticincrement, decrement } = staticmethodSlice.actions
export default staticmethodSlice.reducer