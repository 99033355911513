import { createSlice } from '@reduxjs/toolkit'
export const ioenhancementSlice = createSlice({
  name: 'ioenhancement',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    ioenhanceincrement: (state) => {
      state.value = "iop.png";
      state.z=true
      state.im="iopic.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { ioenhanceincrement, decrement } = ioenhancementSlice.actions
export default ioenhancementSlice.reducer