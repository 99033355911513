import { createSlice } from '@reduxjs/toolkit'
export const functionalinterfaceSlice = createSlice({
  name: 'functionalinterface',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    functionalincrement: (state) => {
      state.value ="ftp.png";
      state.z=true;
      state.im="func2.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { functionalincrement, decrement } = functionalinterfaceSlice.actions
export default functionalinterfaceSlice.reducer