// import React,{useState}  from 'react';
import { useDispatch } from 'react-redux';
import { lambadaincrement } from './reducers/lambadaSlice'
import { methodincrement } from './reducers/methodrefSlice'
import { functionalincrement } from './reducers/functionalinterfaceSlice'
import { streamincrement } from './reducers/streamapiSlice';
import { staticincrement } from './reducers/staticmethodSlice';
import { collecotrincrement } from './reducers/collectorsclassSlice';
import { optionalincrement } from './reducers/optionalclassSlice';
import { baseincrement } from './reducers/base64Slice';
import { parallelincrement } from './reducers/parallelarraysortingSlice';
import { ioenhanceincrement } from './reducers/ioenhancementSlice';
import { concurrencyincrement } from './reducers/concurrencySlice';

export default function Comp1()
{
  
  const dispatch=useDispatch();
 let z=true;
    return(
        <div>
<div class="accordion" id="accordionPanelsStayOpenExample" style={{width:250,fontFamily:"Times New Roman"}} >
  <div class="accordion-item" >
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}
          onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);

          dispatch(functionalincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
          
        }}>
          Functional interface
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Single Absract Method(SAM)</strong> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}} >
        <label  onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(lambadaincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }}>Lambada Expressions</label>
       
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>How Lambda Works.</strong> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        // sety(!z);
        // setx(false);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(methodincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
       
        Method References
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Method Properties</strong> 
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed"  onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(streamincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }}type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
  
        Stream API
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Methods of Stream</strong> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(staticincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Static Method in interface
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Static method calling</strong> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(collecotrincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Collectors class
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Working of Collectors Class</strong> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(optionalincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Optional Class
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Working of Optional Class</strong> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(baseincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseEight" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Base64 Encode/Decode
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Encoding/Decoding of files</strong> 
      </div>
    </div>   
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", !z);
          dispatch(parallelincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Parallel Array sorting
      </button>
    </h2>
    <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Chunck sorting in Parallel</strong> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", z);
        localStorage.setItem("data11", !z);
          dispatch(ioenhanceincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false" aria-controls="panelsStayOpen-collapseTen" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        IO Enhancement
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Serialization</strong> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" onClick={() => {
        //  setx(!z);
        //  sety(z);
        localStorage.setItem("data1", !z);
        localStorage.setItem("data2", !z);
        localStorage.setItem("data3", !z);
        localStorage.setItem("data4", !z);
        localStorage.setItem("data5", !z);
        localStorage.setItem("data6", !z);
        localStorage.setItem("data7", !z);
        localStorage.setItem("data8", !z);
        localStorage.setItem("data9", !z);
        localStorage.setItem("data10", !z);
        localStorage.setItem("data11", z);
          dispatch(concurrencyincrement());
          console.log("local storage",localStorage.getItem("data1"));
          console.log("local storage",localStorage.getItem("data2"));
          console.log("local storage",localStorage.getItem("data3"));
          console.log("local storage",localStorage.getItem("data4"));
          console.log("local storage",localStorage.getItem("data5"));
          console.log("local storage",localStorage.getItem("data6"));
          console.log("local storage",localStorage.getItem("data7"));
          console.log("local storage",localStorage.getItem("data8"));
          console.log("local storage",localStorage.getItem("data9"));
          console.log("local storage",localStorage.getItem("data10"));
          console.log("local storage",localStorage.getItem("data11"));
        }} type="button" data-bs-toggle="collapse"  data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseEleven" style={{fontSize:"12pt",backgroundColor:"teal",color:"white"}}>
        Concurrency
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong style={{fontSize:"8pt",color:"purple"}}>Thread Concurrency</strong> 
      </div>
    </div>
    
  </div>
</div>
            
</div>

    );
}

