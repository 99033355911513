import { createSlice } from '@reduxjs/toolkit'
export const collectorsclassSlice = createSlice({
  name: 'clpt.png',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    collecotrincrement: (state) => {
      state.value = "clpt.png";
      state.z=true
      state.im="clp.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { collecotrincrement, decrement } = collectorsclassSlice.actions
export default collectorsclassSlice.reducer