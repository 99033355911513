export default function Nav()
{
    return(
        <>
         <div >
         <form> 
         <nav class="navbar navbar-expand-lg bg-body-tertiary" style={{backgroundColor:"teal",color:"white",height:20,fontFamily:"Times New Roman"}}>
            
  <div class="container-fluid" style={{backgroundColor:"teal",color:"white"}}>
    <a class="navbar-brand" style={{backgroundColor:"teal",color:"white",marginTop:30,fontWeight:700,fontSize:"12pt"}}>Home</a>
    <a class="navbar-brand" style={{backgroundColor:"teal",color:"white",marginTop:30,fontWeight:700,fontSize:"12pt"}}>Products Us</a>
    <a class="navbar-brand" style={{backgroundColor:"teal",color:"white",marginTop:30,fontWeight:700,fontSize:"12pt"}}>Downloads</a>
    <a class="navbar-brand" style={{backgroundColor:"teal",color:"white",marginTop:30,fontWeight:700,fontSize:"12pt"}}>Pricing</a>
    <a class="navbar-brand" style={{backgroundColor:"teal",color:"white",marginTop:30,fontWeight:700,fontSize:"12pt"}}>Contact Us</a>
    
      <input  type="search" placeholder="Search" aria-label="Search" class="form-control" aria-describedby="inputGroup-sizing-sm" style={{width:"30%" ,padding:"10",borderRadius:"30px",color:"green",borderColor:"green",marginTop:"10px",fontSize:"12pt"}}/>
      <button class="btn btn-success" type="submit" style={{marginTop:"10px",fontSize:"12pt"}}>Search</button>
      
   
  </div>
</nav>
</form>
         </div>
         </>
      
);
}