import { createSlice } from '@reduxjs/toolkit'
export const concurrencySlice = createSlice({
  name: 'concurrency',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    concurrencyincrement: (state) => {
      state.value = "conpic.png";
      state.z=true
      state.im="conp.png"
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { concurrencyincrement, decrement } = concurrencySlice.actions
export default concurrencySlice.reducer