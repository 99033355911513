import { createSlice } from '@reduxjs/toolkit'
export const parallelarraysortingSlice = createSlice({
  name: 'parallelarray',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    parallelincrement: (state) => {
      state.value ="pap.png";
      state.z=true
      state.im="pappic.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { parallelincrement, decrement } = parallelarraysortingSlice.actions
export default parallelarraysortingSlice.reducer