import { createSlice } from '@reduxjs/toolkit'
export const methodrefSlice = createSlice({
  name: 'methodref',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    methodincrement: (state) => {
      state.value = "mrp.png";
      state.z=true
      state.im="mrppic.png"
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { methodincrement, decrement } = methodrefSlice.actions
export default methodrefSlice.reducer