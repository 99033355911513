import { createSlice } from '@reduxjs/toolkit'
export const streamapiSlice = createSlice({
  name: 'streamapi',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    streamincrement: (state) => {
      state.value = "sap.png";
      state.z=true;
      state.im="sappic.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { streamincrement, decrement } = streamapiSlice.actions
export default streamapiSlice.reducer