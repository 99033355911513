import { createSlice } from '@reduxjs/toolkit'
export const lambadaSlice = createSlice({
  name: 'lambadamethod',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    lambadaincrement: (state) => {
      state.value = "lep.png";
      state.z=true;
      state.im="lambadapic.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { lambadaincrement, decrement } = lambadaSlice.actions
export default lambadaSlice.reducer