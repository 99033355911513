import { createSlice } from '@reduxjs/toolkit'
export const optionalclassSlice = createSlice({
  name: 'optionalclass',
  initialState: {
    value:" ",
    z:true,
    im:" ",
  },
  reducers: {
    optionalincrement: (state) => {
      state.value = "opt.png";
      state.z=true
      state.im="opp.png";
    },
    decrement: (state) => {
    
      state.value = state.value - 1
    },
  },
})
export const { optionalincrement, decrement } = optionalclassSlice.actions
export default optionalclassSlice.reducer