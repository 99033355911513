
import './App.css';
import Nav from './Nav';
import Footer from './Footer';
import Comp1 from './Comp1';
import Comp2 from './Comp2';
import Fi from './Fi';
import Fidig from './Fidig';
import Compfi from './Compfi';
import Ficode1 from './Ficode1';
import { useSelector } from 'react-redux'

function App() {
  console.log("...",Comp1.x);
  const lambada=useSelector((state)=>state.lambadamethod.value)
  const method1=useSelector((state)=>state.methodref.value)
  const funcint=useSelector((state)=>state.functionalinterface.value)
  const streamapi1=useSelector((state)=>state.streamapi.value)
  const static1=useSelector((state)=>state.staticmethod.value)
  const collector1=useSelector((state)=>state.collectorsclass.value)
  const optional1=useSelector((state)=>state.optionalclass.value)
  const base1=useSelector((state)=>state.base64.value)
  const parallel1=useSelector((state)=>state.parallelarray.value)
  const ioenhance1=useSelector((state)=>state.ioenhancement.value)
  const concurrency1=useSelector((state)=>state.concurrency.value)

  const fiimg=useSelector((state)=>state.functionalinterface.im)
  const lambadapic=useSelector((state)=>state.lambadamethod.im)
  const methodrefpic=useSelector((state)=>state.methodref.im)
  const streamapipic=useSelector((state)=>state.streamapi.im)
  const staticmethodpic=useSelector((state)=>state.staticmethod.im)
  const collectorclasspic=useSelector((state)=>state.collectorsclass.im)
  const optionalclasspic=useSelector((state)=>state.optionalclass.im)
  const base64pic=useSelector((state)=>state.base64.im)
  const parallelpic=useSelector((state)=>state.parallelarray.im)
  const ioenhanceincrementpic=useSelector((state)=>state.ioenhancement.im)
  const concurrencypic=useSelector((state)=>state.concurrency.im)
  
  return (
    <div className="App">
    <div style={{background:"cyan",color:"green"}}>
      <Nav/>
    </div>   
  <div>
    <div class="row">
      <div class="col" style={{columnWidth:"40%"}}>
      
    <Comp1/>
    </div>
    <div class="col" style={{columnWidth:"30%",fontSize:"8pt", color:"darkcyan", fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify'}}>
      <div class="row">
          <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===true) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={funcint} width="100%" height="80%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===true))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={lambada} width="100%" height="80%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===true))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={method1} width="100%" height="80%"/>:" "} </p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===true))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={streamapi1} width="100%" height="80%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===true))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={static1} width="100%" height="80%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===true))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={collector1} width="100%" height="80%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===true))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={optional1} width="100%" height="60%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===true))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={base1} width="100%" height="60%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===true))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={parallel1} width="100%" height="60%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===true))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={ioenhance1} width="100%" height="60%"/>:" "}</p>
        </div>
        <div>
        <p className="h3"> {(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===true))?<img src={concurrency1} width="100%" height="60%"/>:" "}</p>
        </div>
       
      </div>
      <div class="row">
      
<Ficode1/>

      </div>
      
    </div>
    <div class="col" style={{columnWidth:"40%"}}>
    
{(JSON.parse(localStorage.getItem("data1"))===true) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={fiimg} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===true))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={lambadapic} width="100%" height="40%"/>:" "}   
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===true))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={methodrefpic} width="100%" height="40%"/>:" "}   
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===true))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={streamapipic} width="100%" height="40%"/>:" "}   
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===true))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={staticmethodpic} width="100%" height="40%"/>:" "}  
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===true))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={collectorclasspic} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===true))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={optionalclasspic} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===true))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={base64pic} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===true))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={parallelpic} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===true))&&((JSON.parse(localStorage.getItem("data11"))===false))?<img src={ioenhanceincrementpic} width="100%" height="40%"/>:" "}
{(JSON.parse(localStorage.getItem("data1"))===false) &&((JSON.parse(localStorage.getItem("data2"))===false))&&((JSON.parse(localStorage.getItem("data3"))===false))&&((JSON.parse(localStorage.getItem("data4"))===false))&&((JSON.parse(localStorage.getItem("data5"))===false))&&((JSON.parse(localStorage.getItem("data6"))===false))&&((JSON.parse(localStorage.getItem("data7"))===false))&&((JSON.parse(localStorage.getItem("data8"))===false))&&((JSON.parse(localStorage.getItem("data9"))===false))&&((JSON.parse(localStorage.getItem("data10"))===false))&&((JSON.parse(localStorage.getItem("data11"))===true))?<img src={concurrencypic} width="100%" height="40%"/>:" "}

    </div>
    </div>
    </div>
<div>

  <Footer/>
  </div>

    </div>
  );
}

export default App;
