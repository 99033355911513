import { configureStore } from '@reduxjs/toolkit'
import lambadamethodReducer from '../reducers/lambadaSlice'
import methodrefReducer from '../reducers/methodrefSlice'
import base64Reducer from '../reducers/base64Slice'
import collectorsclassReducer from '../reducers/collectorsclassSlice'
import concurrencyReducer from '../reducers/concurrencySlice'
import functionalinterfaceReducer from '../reducers/functionalinterfaceSlice'
import ioenhancementReducer from '../reducers/ioenhancementSlice'
import optionalclassReducer from '../reducers/optionalclassSlice'
import parallelarraysortingReducer from '../reducers/parallelarraysortingSlice'
import staticmethodReducer from '../reducers/staticmethodSlice'
import streamapiReducer from '../reducers/streamapiSlice'
export default configureStore({
  reducer: {
    lambadamethod:lambadamethodReducer,
    methodref:methodrefReducer,
    base64:base64Reducer,
    collectorsclass:collectorsclassReducer,
    concurrency:concurrencyReducer,
    functionalinterface:functionalinterfaceReducer,
    ioenhancement:ioenhancementReducer,
    optionalclass:optionalclassReducer,
    parallelarray:parallelarraysortingReducer,
    staticmethod:staticmethodReducer,
    streamapi:streamapiReducer,
  },
})  